import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import { CgDanger } from "react-icons/cg";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import slugConf from "../../common/data/games.json";
import { Helmet } from "react-helmet";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from 'react-device-detect';

//Components 
import Banner from "../../components/Banner/Banner";
import GamePagination from "../../components/Pagination/GamePagination";
import MobileProviderGames from "../MobileProviderGames/MobileProviderGames";
import { lotterySeoGp } from "../../components/SeoMeta/SeoMeta";

//Icons
import { Icon } from "@iconify/react";

//Store
import * as actions from "../../store/ducks/lottery/actions";
import * as currentUserActions from "../../store/ducks/currentUser/actions";
import * as gameFavAction from "../../store/ducks/gameFav/actions";

export class Lottery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vendorList: [],
      gameList: [],
      vendorSlug: "",
      vendorId: "",
      fav: "",
      pageLimit: 30,
      search: "",
      imageUrl: "",
      demoUrl: "",
      curPage: 1,
      totalPage: 0,
      memberFavGameList: []
    }
    this.gameRef = createRef();
  }

  componentDidMount() {
    let params = {};
    // params.country_id = this.props.currentUser.data.country_id;

    const path = window.location.pathname.split("/");
    if (path.length >= 3) {
      let curSlug = path[2];
      curSlug = ((slugConf['link-slug'] && slugConf['link-slug'][curSlug]) || '');
      this.setState({
        vendorSlug: curSlug
      });
      params.vendor_slug = curSlug;
      // console.log("[componentDidMount.slug]",curSlug);
    }

    this.props.getVendorList(params);
  }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   const path = window.location.pathname.split("/");
  //   if (path !== undefined && path.length >= 3 && path[2] !== undefined && path[2] !== "") {
  //     if(isDesktop){
  //       this.gameRef.current.scrollIntoView({ behavior: "auto", block: "start" });
  //     }
  //   }

  //   return null;
  // }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]",nextProps,nextProps.rs);
    if(nextProps.location!==undefined && nextProps.location.state!==undefined && nextProps.location.state!==this.props.location.state){
      this.setState({
        vendorSlug: nextProps.location.state.vendorSlug,
        loading: true,
        fav: (nextProps.location.state.vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
      }, ()=>{
        let params = {};
        params.country_id = this.props.currentUser.data.country_id;
        params.language_code = this.props.currentUser.data.language_code;
        params.vendor_slug = nextProps.location.state.vendorSlug;
        params.fav = this.state.fav;
        params.search = this.state.search;
        params.limit = this.state.pageLimit;
        if(localStorage.getItem("accessToken")){
          params.buffer = this.props.currentUser.data.id;
        }
        this.props.getGameList(params);

        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "ke";
          data.slug = nextProps.location.state.vendorSlug;
          this.props.getGameFavList(data);
        }
      });
    }

    if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
      if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
        let vendorList = nextProps.vendor.data.vendor_list;
        let vendorSlug = this.state.vendorSlug;
        if (vendorList.length > 0) {
          vendorSlug = vendorSlug !== "" ? vendorSlug : vendorList[0].game_vendor_slug;
        }
        // console.log("[nextProps.vendor]", vendorSlug);
        this.getVendorGames(vendorSlug, vendorList);
      }
    }

    if (nextProps.game !== undefined && nextProps.game !== this.props.game) {
      if (nextProps.game.responseCode === 200 && nextProps.game.msgType === "success") {
        this.setState({
          gameList: nextProps.game.data.game_list.row,
          imageUrl: nextProps.game.data.image_url,
          loading: false,
          totalPage: nextProps.game.data.game_list.pagination.total_page,
          curPage: nextProps.game.data.game_list.pagination.current_page
        });
      }
    }

    if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
      if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === "success") {
        Swal.close();
        if (nextProps.rs.data.hasOwnProperty("url")) {
          window.open(nextProps.rs.data.url);
        } else if (nextProps.rs.data.hasOwnProperty("gameError")) {
          this.gameErrorResponse(nextProps.rs.data.gameError);
        }
      } else {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} (${nextProps.rs.message[0]}). ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
        });
      }
    }

    if(nextProps.game_fav!==undefined && nextProps.game_fav!==this.props.game_fav){
      if(nextProps.game_fav.responseCode===200 && nextProps.game_fav.msgType==="success"){
        this.setState({
          memberFavGameList: nextProps.game_fav.data.favorite_list
        });
      }
    }

    if(nextProps.update_game_fav!==undefined && nextProps.update_game_fav!==this.props.update_game_fav){
      if(nextProps.update_game_fav.responseCode===200 && nextProps.update_game_fav.msgType==="success"){
        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "ke";
          data.slug = this.state.vendorSlug;
          this.props.getGameFavList(data);

          if(this.state.fav===2){
            // refetch game list if updates occured at favorite tab
            this.setState({
              loading: true
            }, () => {
              let params = {};
              params.country_id = this.props.currentUser.data.country_id;
              params.language_code = this.props.currentUser.data.language_code;
              params.vendor_slug = this.state.vendorSlug;
              params.fav = this.state.fav;
              params.search = this.state.search;
              params.limit = this.state.pageLimit;
              params.buffer = this.props.currentUser.data.id;
              
              this.props.getGameList(params);
            });
          }
        }
      }
    }

    return true;
  }

  gameErrorResponse = (response) => {
    const { t } = this.props;

    switch (response.status) {
      case 1:
        Swal.fire({
          iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          title:  t("global.reminder"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
          showConfirmButton: true,
          confirmButtonText: `${t("global.refresh")}`,
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon", 
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      case 2:
        const currentUserTimeZone = this.props.currentUser.data.time_zone;
        const startTime = moment(response.maintenance_start_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const endTime = moment(response.maintenance_end_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const timeZone = moment()
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("[(GMT] z[)]")
          .toString();
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          icon: "info",
          titleText: `${t("global.under-maintenance")}`,
          html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon", 
        } : [],
        });
        break;
      case 3:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
          
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      default:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
    }
  };

  getVendorGames = (vendorSlug, vendorList) => {
    this.setState({
      vendorSlug: vendorSlug,
      vendorList: vendorList,
      loading: true,
      fav: (vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.vendor_slug = vendorSlug;
      params.fav = (vendorSlug === 'pp') ? 1 : ''; // pp set "hot" category as default, others vendor default as "all" category
      params.search = this.state.search;
      params.limit = this.state.pageLimit;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGameList(params);

      if(localStorage.getItem("accessToken")){
        let data = {};
        data.buffer = this.props.currentUser.data.id;
        data.type = "ke";
        data.slug = vendorSlug;
        this.props.getGameFavList(data);
      }
    });
  }

  getFavGames = (e, fav) => {
    e.preventDefault();
    this.setState({
      loading: true,
      fav: fav,
      gameList: []
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.vendor_slug = this.state.vendorSlug;
      params.fav = fav;
      params.search = this.state.search;
      params.limit = this.state.pageLimit;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGameList(params);
    });
  }

  searchHandler = event => {
    this.setState({
      loading: true,
      search: event.target.value
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.vendor_slug = this.state.vendorSlug;
      params.fav = this.state.fav;
      params.search = event.target.value.toLowerCase().replace(/\\/, "");
      params.limit = this.state.pageLimit;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGameList(params);
    });
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  accessGame = (game) => {
    // console.log(this.state.vendorSlug+' | '+game);
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon',
        } : [],
        });
    } else {
      localStorage.setItem("gameLauncher", 'lottery|' + game + '|' + this.state.vendorSlug);
      window.open('/game-launcher');
    }
  };

  accessDemo = (game) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon',
        } : [],
        });
    }
    else{
      localStorage.setItem("gameLauncher", 'lottery|' + game + '|' + this.state.vendorSlug + '|demo');
      window.open('/game-launcher');
    }
  };

  navigateList = (event, type, customPage = 0) => {
    event.preventDefault();
    let params = {};
    params.country_id = this.props.currentUser.data.country_id;
    params.language_code = this.props.currentUser.data.language_code;
    params.vendor_slug = this.state.vendorSlug;
    params.fav = this.state.fav;
    params.limit = this.state.pageLimit;
    params.search = this.state.search;
    if(localStorage.getItem("accessToken")){
      params.buffer = this.props.currentUser.data.id;
    }
    switch (type) {
      case "page":
        params.page = customPage === 0 ? this.state.curPage : customPage;
        break;
      case "prev":
        params.page = this.state.curPage !== 1 ? this.state.curPage - 1 : this.state.curPage;
        break;
      case "next":
        params.page = this.state.curPage < this.state.totalPage ? this.state.curPage + 1 : this.state.curPage;
        break;
      default:
        params.page = this.state.curPage;
        break;
    }
    this.setState({ loading: true });
    this.props.getGameList(params);
  }

  updateMemberFavGame = (event, id) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon',
        } : [],
        });
    }
    else{
      let params = {};
      params.buffer = this.props.currentUser.data.id;
      params.alt_buffer = id;
      this.props.updateGameFav(params);
    }
  }

  render() {
    const { t } = this.props;
    const gameIcon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const favIcon = this.imageImport(
      require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/)
    );

    const tmpImg = this.imageImport(require.context("../../assets/images/placeholder/", false, /\.(png|jpe?g|svg)$/));
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    let imageLanguage = "";
    switch (this.props.currentUser.language_code) {
        case "zh-CN":
            imageLanguage = "-cn";
            break;
        // case "ms":
        //     imageLanguage = "-ms"
        //     break;
        case "id":
            imageLanguage = "-id" 
            break;
        default:
            imageLanguage = "";
            break;
    }
    
    let seoMeta = null;
    let gameCategory = this.state.vendorList ? this.state.vendorList : [];
    let curGameCategory = this.state.vendorList.filter((val) => val.game_vendor_slug === this.state.vendorSlug);
    let s3ImageUrl = process.env.REACT_APP_S3_IMG_URL;
    let languageCode = this.props.currentUser.data.language_code;
    let lang = languageCode === "zh-CN" ? "zh_CN" : "en_US";
    // console.log("[render]", this.state.vendorSlug, gameCategory, games);
    const truncate = (value) =>
    value?.length > 17 ? `${value.substring(0, 17)}...` : value;
    let games = this.state.gameList ? this.state.gameList.map((game, idx) => {
      let title = truncate(game.game_title);
      let img_url = '';
      switch (this.state.vendorSlug) {
        case 'qt':
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${game.game_code}.png`;
          break;
        case 'sb':
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${game.game_code}.png`;
          break;
        case 'gp':
          seoMeta = <><Helmet><title>{process.env.REACT_APP_WEBSITE_TITLE} | {t(`routes:title.lottery-gp`)}</title></Helmet>{lotterySeoGp}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${game.game_code}.png`;
          break;
        default:
          img_url = tmpImg['tmp_image.png'];
          break;
      }
      return {
        id: game.id,
        name: title,
        code: game.game_code,
        demo: game.support_demo,
        img_url: img_url,
        rtg_rate: game.rtg_rate
      }
    }) : [];
    return (
      <Auxiliary>
        {seoMeta !== null && seoMeta}
        {
          isDesktop ? (
            <>
              {/* <Banner category="lottery" bannerImg="lottery-banner.webp" /> */}
              {/* <Banner category="home" /> */}
              <section className="global-game-section">
              <div className="banner-col">
                            <div className="banner-item">
                                <img src={banner[`lottery-banner${imageLanguage}.webp`]} />
                            </div>
                        </div>
                <div className="x-container-no-m">
                  <div className="game-container p-4">
                    <div className="game-category">
                      <ul className="row p-0 g-3">
                        {gameCategory &&
                          gameCategory.map((item, index) => (
                            <li className="game-ctg-col" key={index}>
                              <Link className={`game-link ${item.hot_status === 1 ? 'hot' : ''} ${this.state.vendorSlug === item.game_vendor_slug ? "active" : ""}`} to={{ pathname: `/lottery/${((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || '')}`, state: {vendorSlug: item.game_vendor_slug} }}>
                                <span>
                                  <img className="icon-grey" src={gameIcon[`${item.game_vendor_slug}-grey.svg`]} alt={`${item.game_vendor_slug}-icon`} />
                                  <img className="icon-white" src={gameIcon[`${item.game_vendor_slug}-white.svg`]} alt={`${item.game_vendor_slug}-icon`} />
                                </span>
                                <p>{t(`vendor.${item.game_vendor_slug}`)}</p>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="game-header my-4">
                      <ul>
                        <p>{t("global.sort-by")}:</p>
                        <li onClick={(event) => this.getFavGames(event, "")} className={this.state.fav === "" ? "active" : null}>
                          <Icon icon="mdi:view-grid" />
                          <span>{t("global.all")}</span>
                        </li>                        
                        <li onClick={(event) => this.getFavGames(event, 1)} className={this.state.fav === 1 ? "active" : null}>
                          <Icon icon="ic:round-local-fire-department" />
                          <span>{t("global.hot")}</span>
                        </li>
                        <li onClick={(event) => this.getFavGames(event, 2)} className={this.state.fav === 2 ? "active" : null}>
                          <Icon icon="mdi:cards-heart" />
                          <span>{t("global.favorite")}</span>
                        </li>

                      </ul>
                      <div className="search-bar">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("global.search")}
                          aria-label="Search"
                          onChange={(event) => this.searchHandler(event)}
                        />
                        <Icon icon="uil:search" />
                      </div>
                    </div>

                    <div className="game-list" ref={this.gameRef}>
                      <div className="row g-3">
                        {this.state.loading ? (
                          <>
                            {(() => {
                              let display = [];
                              for (var i = 0; i < 10; i++) {
                                display.push(
                                  <div className="col-4 col-5ths" key={i}>
                                    <div className="logo-cont">
                                      <Skeleton count={1} />
                                    </div>
                                    <div className="d-flex align-items-center p-2 title-cont">
                                      <Skeleton count={1} width={80} />
                                      <img className="ms-auto" src={gameIcon[`${this.state.vendorSlug}-grey.svg`]} alt={`${this.state.vendorSlug}-icon`} />
                                    </div>
                                  </div>
                                );
                              }
                              return display;
                            })()}
                          </>
                        ) : (
                          <>
                            {this.state.fav===2 && !localStorage.getItem("accessToken") ?
                              <div className="col-12 text-center">{t("game-page.no-info")}</div>
                            :games &&
                              games.map((game, index) => {
                                return (
                                  <div className="col-4 col-5ths" key={index}>
                                    <div className="logo-cont">
                                      <img
                                        className="w-100"
                                        src={game.img_url}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = this.src = tmpImg["tmp_image.png"];
                                        }}
                                        alt={game.code}
                                      />
                                      
                                      {(()=>{
                                        if(this.state.memberFavGameList.length>0 && this.state.memberFavGameList.includes(game.id)){
                                          return (
                                            <img role="button" className="fav" src={favIcon["fav-red.png"]} alt="fav"
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                        else{
                                          return (
                                            <img role="button" className="fav" src={favIcon["fav-gray.png"]} alt="fav"
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                      })()}

                                      <div className="overlay">
                                        {(() => {
                                          if (gameCategory !== undefined && gameCategory.length > 0 && curGameCategory !== undefined && curGameCategory.length > 0) {
                                            if (curGameCategory[0].maintenance_status === 0) {
                                              return (
                                                <>
                                                  <div onClick={() => this.accessGame(game.code)} role="button" className="play-now">
                                                    {t("global.play-now")}
                                                  </div>

                                                  {game.demo === 1 && (
                                                    <div onClick={() => this.accessDemo(game.code)} role="button" className="demo">
                                                      {t("global.demo")}
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            }
                                            else {
                                              return (
                                                <>
                                                  <div className="under-maintenance-text">
                                                    <CgDanger />
                                                    <p>
                                                      {t("global.under-maintenance")}
                                                      <br />
                                                      {t("global.will-be-back-soon")}!
                                                    </p>
                                                  </div>
                                                </>
                                              )
                                            }
                                          }
                                        })()}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center p-2 title-cont">
                                      <div>
                                        <h6 className="mb-0">{game.name}</h6>   
                                        <p className="rtp">{t("game-page.rtg")}:<span>{game.rtg_rate.toString()}%</span></p>                                     
                                      </div>

                                      <img
                                        className="ms-auto"
                                        src={gameIcon[`${this.state.vendorSlug}-grey.svg`]}
                                        alt={`${this.state.vendorSlug}-icon`}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                      <GamePagination 
                        totalPage={this.state.totalPage}
                        curPage={this.state.curPage}
                        data={games}
                        navigateList={(event, type, page)=> this.navigateList(event, type, page)}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>

          ) : (
            <MobileProviderGames
              returnPage={"/lottery"}
              title={t(`vendor.${this.state.vendorSlug}`)}
              gamesList={games}
              gameCategory={gameCategory}
              selectedVendor={curGameCategory}
              gameType="lottery"
              memberFavGamesList={this.state.memberFavGameList}
              searchHandler={this.searchHandler}
              accessGameFunc={this.accessGame}
              accessDemoFunc={this.accessDemo}
              navigateList={this.navigateList}
              updateMemberFavGame={this.updateMemberFavGame}
              changeTab={this.getFavGames}
              {...this.state}
              {...this.props}
            />
          )
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]", state);
  return {
    vendor: state.lottery.vendor,
    game: state.lottery.game,
    rs: state.lottery.rs,
    currentUser: state.currentUser,
    game_fav: state.gameFav.game_fav,
    update_game_fav: state.gameFav.update_game_fav
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVendorList: (params) => dispatch(actions.getVendorList(params)),
    getGameList: (params) => dispatch(actions.getGameList(params)),
    accessGame: (params, vendor) => dispatch(actions.accessGame(params, vendor)), // still using?
    setGameQT: (params) => dispatch(currentUserActions.setGameQT(params)), // still using?
    getGameFavList: (params) => dispatch(gameFavAction.getGameFavList(params)),
    updateGameFav: (params) => dispatch(gameFavAction.updateGameFav(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Lottery));
